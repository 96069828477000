import useLocales from '@hooks/useLocales';
import { Typography } from '@material-ui/core';

interface props {
  color?: string;
}

const PrivacyPolicyLink = ({ color }: props) => {
  const { t } = useLocales();
  return (
    <Typography variant="body2" component="span">
      <a
        style={{
          color
        }}
        href={
          // company?.privacy_statement ||
          'https://recrout.com/en/privacy-policy/'
        }
        target="_blank"
        rel="noreferrer"
      >
        {t('Privacy Policy')}
      </a>
    </Typography>
  );
};

const GeneralTermsLink = ({ color }: props) => {
  const { t } = useLocales();
  return (
    <Typography variant="body2" component="span">
      <a
        style={{
          color
        }}
        href={
          // company?.general_terms ||
          'https://recrout.com/en/general-terms/'
        }
        target="_blank"
        rel="noreferrer"
      >
        {t('Terms of Service ')}
      </a>
    </Typography>
  );
};

export { PrivacyPolicyLink, GeneralTermsLink };
