import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import {
  deleteRoom,
  getAllRooms,
  getMessageRoom,
  getPrivateRooms,
  postRooms,
  postMessage,
  getNotifications,
  getUnreadCount,
  getAllNotifications,
  getTemplates,
  postTemplates,
  postTemplateCount,
  getColleagues,
  getPrivateMessages,
  postPrivateMessage,
  deletePrivate,
  getCandidates
} from '../../requests/chat';

import { RootChat } from '../../types/Chat';

const initialState: RootChat = {
  createRoom: [],
  rooms: [],
  messagesRoom: [],
  showChat: false,
  messengerState: false,
  notifications: [],
  unReadCount: 0,
  forMessangerMessagesRoom: [],
  allMessangerRooms: [],
  countLoaded: false,
  allNotifications: [],
  templates: [],
  colleagues: [],
  privateMessagesList: [],
  candidates: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addRoom(state, action) {
      state.createRoom = action.payload;
    },
    allRooms(state, action) {
      state.rooms = action.payload;
    },
    allMessengerRooms(state, action) {
      state.allMessangerRooms = action.payload;
    },
    messages(state, action) {
      state.messagesRoom = action.payload;
    },
    allNotifications(state, action) {
      state.allNotifications = action.payload;
    },
    forMessangerMessages(state, action) {
      state.forMessangerMessagesRoom = action.payload;
    },
    openOrCLoseChat(state, action) {
      state.showChat = action.payload;
    },
    messagesActive(state, action) {
      state.messengerState = action.payload;
    },
    notifications(state, action) {
      state.notifications = action.payload;
    },
    countLoaded(state, action) {
      state.countLoaded = action.payload;
    },
    unRead(state, action) {
      state.unReadCount = action.payload;
    },
    templates(state, action) {
      state.templates = action.payload;
    },
    colleagues(state, action) {
      state.colleagues = action.payload;
    },
    privateMessagesList(state, action) {
      state.privateMessagesList = action.payload;
    },
    candidates(state, action) {
      state.candidates = action.payload;
    }
  }
});

export default slice.reducer;

export const getUnRead = () => {
  getUnreadCount()
    .then((res) => {
      dispatch(slice.actions.countLoaded(true));
      dispatch(slice.actions.unRead(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getNotification = () => {
  getNotifications()
    .then((res) => {
      dispatch(slice.actions.notifications(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const activeMessenger = (status: boolean) => {
  dispatch(slice.actions.messagesActive(status));
};
export const activeChat = (status: boolean) => {
  dispatch(slice.actions.openOrCLoseChat(status));
};
export const postRoom = (candidate: any) => {
  postRooms(candidate)
    .then((res) => {
      dispatch(slice.actions.addRoom(res));
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const allRooms = () => {
  getAllRooms()
    .then((res) => {
      dispatch(slice.actions.allRooms(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAllMessengerRooms = () => {
  getAllRooms()
    .then((res) => {
      dispatch(slice.actions.allMessengerRooms(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const privateRoom = (candidate: any) => {
  const validData = candidate.split('+').join('%2B');
  getPrivateRooms(validData)
    .then((res) => {
      dispatch(slice.actions.allRooms(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const roomMessage = (room: any) => {
  getMessageRoom(room)
    .then((res) => {
      dispatch(slice.actions.messages(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAllNotificationsMessages = () => {
  getAllNotifications()
    .then((res) => {
      dispatch(slice.actions.allNotifications(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const forMessangerRoomMessage = (room: any) => {
  getMessageRoom(room)
    .then((res) => {
      dispatch(slice.actions.forMessangerMessages(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const sendMessage = (data: any, changeRoom: any, role: any) => {
  if (role === 'candidate' || role === 'employee') {
    postMessage(data).then(() => {
      roomMessage(data.room);
    });
  } else {
    postMessage(data)
      .then(() => {
        roomMessage(changeRoom);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const sendMessageFromMessanger = (
  data: any,
  changeRoom: any,
  role: any
) => {
  if (role === 'candidate' || role === 'employee') {
    postMessage(data).then(() => {
      forMessangerRoomMessage(data.room);
    });
  } else {
    postMessage(data)
      .then(() => {
        forMessangerRoomMessage(changeRoom);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const deleteRoomChange = (rooom: number) => {
  deleteRoom(rooom).then(() => {
    allRooms();
  });
};
export const getMessengerTemplate = (name: string) => {
  getTemplates(name)
    .then((res) => {
      dispatch(slice.actions.templates(res.results));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const saveTemplate = (data: any) => {
  postTemplates(data);
  getMessengerTemplate('');
};
export const addTemplateCount = (id: number) => {
  postTemplateCount(id);
};
export const getColleaguesList = (name: string) => {
  getColleagues(name)
    .then((res) => {
      dispatch(slice.actions.colleagues(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getPrivateMessagesList = (userId: number, colleagueId: number) => {
  getPrivateMessages(userId, colleagueId)
    .then((res) => {
      dispatch(slice.actions.privateMessagesList(res));
    })
    .catch((err) => {
      console.log(err);
    });
};
export const sendPrivateMessage = (
  data: any,
  userId: number,
  colleagueId: number
) => {
  postPrivateMessage(data)
    .then(() => getPrivateMessagesList(userId, colleagueId))
    .catch((err) => {
      console.log(err);
    });
};
export const deletePrivateConversation = (
  userId: number,
  colleagueId: number
) => {
  deletePrivate(userId, colleagueId).then(() => {
    getColleaguesList('');
  });
};
export const getCandidatesList = (name: string) => {
  getCandidates(name)
    .then((res) => {
      dispatch(slice.actions.candidates(res.results));
    })
    .catch((err) => {
      console.log(err);
    });
};
