import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import cmsReducer from './slices/cms';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import setCustomers from './slices/customrs';
import dashboardReducer from './slices/dashboard';
import showcaseReducer from './slices/showcase';
import generalRecruiter from './slices/generalRecruiter';
import notificationsReducer from './slices/notifications';
import candidateReducer from './slices/candidate';
import fixturesReducer from './slices/fixtures';
import filtersReducer from './slices/filters';
import errorsReducer from './slices/errors';
import loadingReducer from './slices/loading';
import invitationsReducer from './slices/invitations';
import productsReducer from './slices/productsReducer';
import campaignsReducer from './slices/campaignsReducer';
import dialogs from './slices/dialogs';
import chatReducer from './slices/chat';
import jobSlice from './slices/jobSlice';
import cultureProfileReducer from './slices/cultureProfileReducer';
import apiKeys from './slices/apiKeys';
import assessment from './slices/assessment';
import notificationReducer from './slices/notification';

const rootReducer = (history: any) =>
  combineReducers({
    loading: loadingReducer,
    errors: errorsReducer,
    cms: cmsReducer,
    settings: settingsReducer,
    customers: setCustomers,
    candidate: candidateReducer,
    authJwt: authJwtReducer,
    dashboard: dashboardReducer,
    showcase: showcaseReducer,
    notifier: notificationsReducer,
    invitations: invitationsReducer,
    generalRecruiter,
    fixturesReducer,
    filtersReducer,
    productsReducer,
    campaignsReducer,
    router: connectRouter(history),
    dialogs,
    chat: chatReducer,
    notification: notificationReducer,
    job: jobSlice,
    cultureProfileReducer,
    apiKeys,
    assessment
  });

export { rootReducer };
